/** @jsx jsx */
import { jsx } from 'theme-ui'

import Layout from 'src/components/layout'
import SEO from 'src/components/seo'

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Стать автором" />
      <div
        sx={{ variant: 'boxes.container', maxWidth: 'content', pb: 4, pt: 6 }}
      >
        <h1>Как стать автором на Перемена.медиа</h1>
        <p>
          <strong>
            Мы открыты для тех, кто хочет поделиться своим экспертным мнением.
          </strong>
        </p>
        <p>
          Родители, педагоги, психологи – все те, кто задаёт новые тренды в
          образовании и воспитании детей в Казахстане. Нам важен именно ваш
          уникальный опыт и знания. Мы верим, что такой материал будет учить,
          развлекать и информировать наших читателей.
        </p>
        <p>
          <strong>
            Пишите на <a href="mailto:ask@peremena.media">ask@peremena.media</a>
          </strong>
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
